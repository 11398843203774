<template>
  <div class="order_instructions">
    <div class="instructions_header">
      <div class="header_title">预约须知</div>
      <img src="@/assets/images/order-instructions.png">
    </div>
    <div class="instructions_body">
      <p>一、网上预约是政府便民惠民、提高服务水平的一项新举措。预约人在预约前，须仔细阅读本须知。网上预约申请不收取任何手续费。</p>
      <p>二、网上预约需实名制。</p>
      <p>三、您在预约申请前，可以在政务服务网了解您申请的相关业务需提交的申请材料和办事流程。</p>
      <p>四、同一用户每天只能预约2次，预约的身份证和实际办理的身份证不一致时，窗口受理人员有权拒绝办理。</p>
      <p>五、预约成功后，请在预约时间段前领取预约号;无法在预约时间前来取号的，请务必于预约时段前取消预约，未取消预约3次以上及未按照要求在政务大厅咨询台凭有效身份证件取号3次以上的，本系统自动将其列入黑名单，30个自然日内将无法再进行网上预约。</p>
      <p>六、同一业务当月累计取消预约3次的，本系统自动将其列入黑名单，15个自然日内将无法再进行网上预约。</p>
      <p>七、为保证您业务的顺利办理，请如实填写相关信息。</p>
    </div>
    <div class="instructions_bottom" @click="goTo">进入预约</div>
    <div
      class="suspension"
      :style="{ top: top + 'px' }"
      @click="toList"
      @touchmove.prevent="position"
    >
      <span>预约</span>
      <span>记录</span>
    </div>
  </div>
</template>

<script>
import {
  Icon
} from "vant";
export default {
  name: "orderInstructions",
  components: {
    [Icon.name]:Icon
  },
  data() {
    return {
      top: 0,
      clientY: 0,
    };
  },
  mounted() {
    this.clientY = document.documentElement.clientHeight;
    this.top = this.clientY - 200;
  },
  methods: {
    //悬浮按钮拖动定位
    position(e) {
      if (
        e.touches[0].clientY >= 0 &&
        e.touches[0].clientY < this.clientY - 30
      ) {
        this.top = e.touches[0].clientY;
      }
    },
    toList(){
      this.$router.push("/orderRecords");
    },
    goTo(){
      this.$router.push("/orderMatter");
    }
  },
};
</script>

<style lang="scss" scoped>
.order_instructions{
  height: 100vh;
  overflow: hidden;
  padding: 12px 12px 24px;
  background-image: linear-gradient(to bottom,
    #3AA0FF 0%,
    #ffffff 70%,
    #ffffff 100%
  );
  display: flex;
  flex-direction: column;

  .instructions_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header_title{
      color: #fff;
      font-size: 40px;
    }
    img{
      width: 120px;
      height: 120px;
    }
  }

  .instructions_body{
    padding: 16px 12px;
    margin-bottom: 36px;
    border-radius: 4px;
    background-color: #ffffff;
    overflow: auto;
    flex: 1;
    p{
      color: #333333;
      font-size: 14px;
      line-height: 20px;
      +p{
        margin-top: 8px;
      }
    }
  }

  .instructions_bottom{
    border-radius: 5px;
    background-color: #1677FF;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
  }
}
.suspension {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: #519aff;
  width: 48px;
  height: 48px;
  right: 24px;
  color: #fff;
  font-size: 12px;
  background-image: linear-gradient(to bottom,#1677FF,#3AA0FF);
  box-shadow: 0 3px 12px rgba($color: #3AA0FF, $alpha: 0.57);
  border-radius: 50%;
  z-index: 100;
  user-select: none;
  transition: none;
  span{
    line-height: 16px;
  }
}
</style>